<template>
  <div>
    <b-modal 
      v-model="showModal"
      button-size="sm" id="nfcModal"
      no-close-on-esc no-close-on-backdrop hide-header centered scrollable>
      <div>
        <div class="flex-row" style="justify-content: space-between;">
          <h4 class="mb-1">
            <span v-if="showInit">NFC SERVICE</span>
            <span v-else>ASSET TAG</span>
          </h4>
        </div>
        <div class="mb-3" style="font-size: 10px">{{scanCode}}</div>
        <div v-if="showInit" style="display: flex; align-items: center;">
          <div>
            <div v-if="!initError">Activate NFC to scan Trakk asset tags.</div>
            <div v-else>ERROR: {{ initError }}</div>
            <div v-if="state.nfcStatus === 'failed'" class="mt-2">Find out more at 
              <a href="https://docs.trakkassets.com/guide">Trakk Docs</a>
            </div>
          </div>
        </div>
        <div v-else-if="write" style="display: flex; align-items: center;">
          Waiting to write '{{ write.type === 'asset' ? $schema.setup.idType : write.type }}' to tag.
        </div>
        <div v-else-if="written" style="display: flex; align-items: center;">
          Tag updated. '{{ written === 'asset' ? $schema.setup.idType : written }}' saved on tag.
        </div>
        <div v-else-if="loading" style="display: flex; align-items: center;">
          <div class="tk-app-spinner"></div>
        </div>
        <div v-else-if="asset" style="display: flex; align-items: center;">
          <table>
            <tr>
              <td class="data-cell"><b>{{ $schema.setup.idType }}:</b></td>
              <td class="data-cell">{{ asset.assetID }}</td>
            </tr>
            <tr v-if="asset.type">
              <td class="data-cell"><b>TYPE:</b></td>
              <td class="data-cell">{{ asset.type }}</td>
            </tr>
            <tr v-if="asset.parent">
              <td class="data-cell"><b>PARENT ASSET:</b></td>
              <td class="data-cell">{{ asset.parent }}</td>
            </tr>
            <tr v-for="(v, k) in asset.attributes" v-bind:key="k">
              <td v-if="k!=='type'" class="data-cell"><b>{{ String(k).toUpperCase() }}:</b></td>
              <td v-if="k!=='type'" class="data-cell">{{v}}</td>
            </tr>
          </table>
          <!-- <img v-if="scanThumb" :src="scanThumb" width="100%"></img> -->
          <!-- <div v-else id="quaggaView" class="quaggaView"></div> -->
        </div>
        <div v-else-if="offline" style="display: flex; align-items: center;">
          Offline, tag owner cannot be verified
        </div>
        <div v-else-if="tagAid">
          <div class="mb-2">
            Tag registered as Point {{tagAid}}
          </div>
          <div v-if="currentAssets">
            <label v-if="currentAssets.length > 1">Select from Multiple Assets</label>
            <label v-else>Current Asset is in another Unit</label>
            <b-table striped hover :items="currentAssets" :fields="['assetID','Info']" small>
              <template v-slot:cell(assetID)="data">
                <b-link @click="openAsset(data.item)">
                  {{ data.item.assetID }}
                </b-link>
              </template>
              <template v-slot:cell(Info)="data">
                <div class="flex-row">
                  <div>
                    <div style="overflow:hidden; white-space: nowrap;">{{ data.item.type }}</div>
                    <div>{{ data.item.label }}</div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <div v-else>No current assets.</div>
        </div>
        <div v-else-if="state.tag.code">
          <p>NFC Tag not recognised. <br>Register Tag as new Point to continue</p>
          <label style="white-space:nowrap">NFC (uid)</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-button :variant="active ? 'success' : 'info'" :class="{ 'nfc-active': active }"
                style="font-size: 11px; padding: 4px" @click="restart">
                NFC
              </b-button>
            </b-input-group-prepend>
            <b-form-input :value="state.tag.code" disabled></b-form-input>
          </b-input-group>
          <label class="mt-3">Point ID</label>
          <b-form-input v-model="newPointID"></b-form-input>
        </div>
        <div v-else style="display: flex; align-items: center;">
          Waiting for tag
        </div>
      </div>
      <template v-slot:modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <div>
            <b-button class="mr-2" variant="secondary" @click="close">
              <span v-if="write">Cancel</span>
              <span v-else>Close</span>
            </b-button>
          </div>
          <div>
            <div v-if="!write">
              <b-button v-if="showInit && !initError" 
                class="ml-3" variant="info" 
                @click="startReader">
                Start NFC
              </b-button>
              <b-button v-if="asset" 
                class="mr-2" variant="info" 
                @click="openAsset">
                Open Asset
              </b-button>
              <b-button v-if="callback && state.tag.code" 
                class="mr-2" variant="info" 
                @click="runCallback">
                Continue
              </b-button>
              <b-button v-if="!tagAid && state.tag.code" 
                variant="info" :disabled="!newPointID || saveInProgress" 
                @click="saveNewTag">
                Register
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal id="nfcTest" hide-header hide-footer centered scrollable>
      <div>
        <h4>Test NFC</h4>
        <div>
          <b-input v-model="test.id"></b-input>
          <div class="mt-2">
            <b-button @click="testButtons('read')">Read</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- <point-search v-model="pointSelect" @asset="setAsset" /> -->
  </div>
</template>
<script>
import UserRoles from "@/mixins/userRoles";
import Analytics from "@/mixins/analytics";
import { getAsset } from '@/store/firebase.js';
import { searchPoint } from '@/store/tsSearch';
import DateTime from '@/mixins/datetime';
import Alert from '@/mixins/alert';
import PointSearch from '@/components/PointSearch.vue'
// import _cloneDeep from 'lodash/cloneDeep'
// import _isEqual from 'lodash/isEqual'
import _forEach from 'lodash/forEach'
// import _findIndex from 'lodash/findIndex'
const encoder = new TextEncoder()
import { Nfc, NfcUtils, NfcTagTechType } from '@capawesome-team/capacitor-nfc';
import { Device } from '@capacitor/device';

export default {
  name: "NfcScanner",
  mixins: [ Analytics, UserRoles, DateTime, Alert ],
  components: { PointSearch },
  data() {
    return {
      showModal: false,
      showInit: false,
      initError: null,
      written: null,
      scanCtrl: null,
      restartCtrl: null,
      reader: null,
      newAid: null,
      asset: null,
      offline: false,
      loading: false,
      saveInProgress: false,
      newPointID: null,
      currentAssets: null,
      isSupported: false,
      permitStatus: false,
      platform: null,
      test: {
        id: '0400F3B2B91191'
      }
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    scanCode() {
      return this.$store.state.Nfc.tag?.code
    },
    tagAid() {
      return this.$store.state.Nfc.tag?.aid || this.scanCode ? this.$store.state.Db.tags[String(this.scanCode).toUpperCase()]?.assetID : null
    },
    state() {
      return this.$store.state.Nfc
    },
    write() {
      return this.$store.state.Nfc.toWrite
    },
    callback() {
      return this.$store.state.Nfc.callback !== null
    },
    autocallback() {
      return this.$store.state.Nfc.autocallback !== null
    },
    nfcAtt() {
      let sa = (this.$schema.attributes || []).find((a) => {
        if(a.type === 'code' && a.setup.includes('nfc')) return true
        return false
      })
      if(!sa) return false
      return String(sa.key).toLowerCase().trim()
    },
    loggedIn() {
      return this.$store.state.Auth.user.loggedIn
    },
    active() {
      return !['stopped', 'notsupported'].includes(this.state.nfcStatus)
    },
  },
  destroyed () {
    if(this.scanCtrl) this.scanCtrl.abort()
    if(this.restartCtrl) this.restartCtrl.abort()
    this.$store.commit('SET_NFC_STATUS', 'stopped')
  },
  created () {
    this.checkSupport()
    this.checkPlatform()
    this.restartCtrl = new AbortController();
    this.restartCtrl.signal.onabort = () => {
      // console.log('NFC focus controller stopped')
    };
    window.addEventListener('focus', () => { 
      // console.log('window focus, start NFC')
      this.restart()
    }, { signal: this.restartCtrl.signal });
    window.addEventListener('blur', () => { 
      // console.log('window blur, stop NFC')
      this.startNFC(false)
    }, { signal: this.restartCtrl.signal });
  },
  watch: {
    async tagAid(aid) {
      this.asset = null
      this.points = null
      this.offline = false
      this.loading = true
      if(!aid) {
        this.loading = false
        return
      }
      // Try setAsset, if returns true then nothing to do.
      let asset = this.setAsset(aid)
      if (asset) return

      let result = await searchPoint(aid)
      let currentAssets = []
      result.points.forEach(p => {
        if(!p.end) currentAssets.push(result.assets[p.aid])
      })
      if(currentAssets.length === 1) {
        let asset = currentAssets[0]
        let currentUnit = this.$store.state.Db.unit.id
        if(asset._unitID !== currentUnit) this.currentAssets = currentAssets
        else {
          await getAsset(asset.assetID).then(()=> {
            this.setAsset(asset.assetID)
          }).catch((err) => {
            console.log('Error getting asset', err)
          })
        }
      }
      else if(currentAssets.length > 0) {
        this.currentAssets = currentAssets
      }
      this.loading = false
    },
    loggedIn: {
      immediate: true,
      async handler (value) {
        // console.log('nfc login', value)
        if(value) {
          // console.log('NFC login', { 
          //   status: this.state.nfcStatus, 
          //   platform: this.platform, 
          //   permit: this.permitStatus 
          // })
          if(!this.isSupported) {
            console.log('NFC not supported')
            this.$store.commit('SET_NFC_STATUS', 'notsupported')
          } 
          else {
            if(!this.permitStatus) await this.checkPermit()
            console.log('Permit status:', this.permitStatus)

            if(this.permitStatus === 'prompt') this.permit()
            else if(this.permitStatus === 'granted') this.startNFC(true)
            else {
              this.$store.commit('SET_NFC_STATUS', 'stopped')
              console.log('NFC permission denied, not starting NFC', { permitStatus: this.permitStatus })
            }
          }
        }
        else this.startNFC(false)
      },
    },
    write (w) {
      if(!this.reader) console.log('NFC not supported')
      else if(w !== null) {
        let message = {
          records: [{
            id: `trakkassets.com/#/${w.type}`,
            recordType: 'mime',
            mediaType: 'application/json',
            data: encoder.encode(JSON.stringify(w.data))
          }]
        }
        this.reader.write(message).then(() => {
          this.written = w.type
          this.$store.commit('SET_NFC_WRITE', null)
          this.$store.dispatch('nfcReadTag', { serialNumber: this.scanCode, message })
        })
        this.showModal = true
      }
    },
    'state.init' (i) {
      if(i) {
        // console.log('NFC init', { status: this.state.nfcStatus, platform: this.platform })
        if(this.platform === 'ios' && this.state.nfcStatus === 'ready') this.startScan()
        else this.restart()
        this.$store.commit('SET_NFC_INIT', false)
      }
    },
    'state.scan' (s) {
      // console.log('NfcScanner, "state.scan"',s)
      if(s) {
        this.startScan()
        this.$store.commit('SET_NFC_SCAN', false)
      }
    },
    callback (a) {
      if(a) {
        if(!this.reader) console.log('NFC not supported')
        this.showModal = true
      }
    }
  },
  methods: {
    async checkSupport() {
      const { isSupported } = await Nfc.isSupported();
      if(!isSupported) this.$store.commit('SET_NFC_STATUS', 'notsupported')
      this.isSupported = isSupported
    },
    async checkPermit() {
      const permit = await Nfc.checkPermissions();
      // console.log('permit', permit)
      this.permitStatus = permit.nfc
    },
    async checkPlatform() {
      const { platform } = await Device.getInfo();
      this.platform = platform
    },
    async testButtons(opt) {
      if(opt === 'read') {
        this.read({ serialNumber: this.test.id, message: {} })
      }
      this.$bvModal.hide('nfcTest')
    },
    async startNFC(value) {  // START NFC service if permissions already exist
      // console.log('startNFC', value)
      if(!this.isSupported) return this.$store.commit('SET_NFC_STATUS', 'notsupported')
      if(value) {
        if(this.permitStatus === 'granted') this.startReader();
        else { console.log('NFC permission denied, not starting NFC', { permitStatus: this.permitStatus }) }
      }
      else {
        await Nfc.removeAllListeners();
        this.$store.commit('SET_NFC_STATUS', 'stopped')
      }
    },
    async permit() { // START NFC service and ask for permissions if don't exist
      if(!this.isSupported) return
      if(!this.permitStatus) await this.checkPermit()
      if(this.permitStatus === 'granted') {
        // NFC access was previously granted, so we can start NFC scanning now.
        this.startReader();
      } else {
        console.log('NFC permission state:', this.permitStatus)
        this.showInit = true
        this.showModal = true
      }
    },
    async restart() { // RESTART NFC service, removes listeners and restarts using permit()
      // console.log('restart NFC')
      if(!this.isSupported) return this.$store.commit('SET_NFC_STATUS', 'notsupported')
      await Nfc.removeAllListeners();
      this.$store.commit('SET_NFC_STATUS', 'stopped')
      setTimeout(() => { this.permit() }, 2000)
    },
    
    async startScan() {
      if(this.active) {
        await Nfc.stopScanSession();
        Nfc.startScanSession()
        this.$store.commit('SET_NFC_STATUS', 'scan')
      }
    },
    async startReader() {
      // console.log('start Reader')
      await Nfc.stopScanSession();
      await Nfc.removeAllListeners();
      // let vm = this

      // console.log('startReader', { device: this.platform })
      Nfc.addListener('nfcTagScanned', async (event) => {
        console.log('NFC tag scanned', { event, platform: this.platform })
        if(this.platform === 'ios') {
          await Nfc.stopScanSession();
          this.$store.commit('SET_NFC_STATUS', 'ready')
        }
        this.read(event)
      });

      if(this.platform === 'ios') {
        this.$store.commit('SET_NFC_STATUS', 'ready')
      }
      else {
        Nfc.startScanSession()
        this.$store.commit('SET_NFC_STATUS', 'scan')
      }

      console.log('NFC scanner started')
      
      this.showInit = false
      this.showModal = false

      // .then((p) => {
      //   console.log('NFC scanner started')
      //   this.$store.commit('SET_NFC_STATUS', 'scan')
      //   this.showInit = false
      //   this.showModal = false
      // })
      // .catch(err => {
      //   Nfc.removeAllListeners();
      //   this.showModal = true
      //   this.showInit = true
      //   this.initError = 'Scanner Failed to Start' + err
      //   console.log('Scanner Failed to Start', err)
      //   this.$store.commit('SET_NFC_STATUS', 'failed')
      // })

      // this.showModal = false
      
      // setTimeout(() => {
      //   if(this.state.nfcStatus === 'init') {
      //     this.initError = 'Failed to start, your browser may not be supported'
      //     this.showInit = true
      //     this.showModal = true
      //     this.$store.commit('SET_NFC_STATUS', 'failed')
      //   }
      // }, 10000)




      
      /*
      if(!('NDEFReader' in window)) return
      this.reader = new NDEFReader()
      console.log('start NFC')
      this.$store.commit('SET_NFC_STATUS', 'init')
      this.reader.addEventListener('reading', event => {
        this.read(event)
      })
      this.scanCtrl = new AbortController();
      this.scanCtrl.signal.onabort = () => {
        console.log('NFC scanner stopped')
      };
      this.reader.scan({ signal: this.scanCtrl.signal })
        .then((p) => {
          console.log('NFC scanner started')
          this.$store.commit('SET_NFC_STATUS', 'scan')
          this.showInit = false
          this.showModal = false
        })
        .catch(err => {
          this.scanCtrl.abort()
          this.showModal = true
          this.showInit = true
          this.initError = 'Scanner Failed to Start' + err
          console.log('Scanner Failed to Start', err)
          this.$store.commit('SET_NFC_STATUS', 'failed')
        })
      this.showModal = false
      setTimeout(() => {
        if(this.state.nfcStatus === 'init') {
          this.initError = 'Failed to start, your browser may not be supported'
          this.showInit = true
          this.showModal = true
          this.$store.commit('SET_NFC_STATUS', 'failed')
        }
      }, 10000)
      /* */
    },
    read (event) {
      this.$store.dispatch('nfcReadTag', event).then(() => {
        if(this.write) return
        if(this.autocallback) return
        this.showModal = true
        this.logEvent('nfc_readtag')
      })
    },
    setAsset(aid) {
      const asset = this.$store.getters.assetByID(aid)
      // console.warn(aid, asset)
      if (!asset) return false

      const result = { 
        assetID: asset.assetID,
        parent: asset.parent,
        type: asset.attributes?.type,
        attributes: {}
      }
      const keys = Object.keys(asset.attributes || {})
      keys.sort()
      keys.forEach(k => {
        if(['type'].includes(k)) return
        result.attributes[k] = asset.attributes[k]
      }) 
      this.asset = result
      this.loading = false
      return true
    },
    saveNewTag() {
      if(!['', null].includes(this.newPointID) && this.state.tag.code) {
        this.saveInProgress = true
        let id = String(this.state.tag.code).toUpperCase()
        this.$store.dispatch("setTag", { id, update: { assetID: this.newPointID } });
      }
      this.newPointID = null
    },
    handleError (err) {
      console.log(err);
    },
    close() {
      if(this.callback) this.$store.dispatch('nfcCallback', { message: 'User aborted'}).then(() => {
        this.close()
      })
      this.showModal = false;
      this.$store.dispatch('nfcClearAll')
      this.clearScan();
    },
    clearScan() {
      this.written = null;
      this.newAid = null;
      this.saveInProgress = false;
    },
    runCallback() {
      this.$store.dispatch('nfcCallback').then(() => {
        this.close()
      })
    },
    openAsset(pick) {
      if(this.asset) this.$router.push(`/home/asset/${this.asset.assetID}`);
      else if(pick) {
        let currentUnit = this.$store.state.Db.unit.id
        if(pick._unitID !== currentUnit) {
          this.setAlert('Switching Unit', 'warning')
          this.$store.dispatch('switchUnit', pick._unitID)
        }
        this.$router.push(`/home/asset/${pick.assetID}`);
      }
      this.close()
    }
  }
};
</script>
<style scoped>
.tk-app-spinner {
  height: 100px;
}
.tk-app-spinner::before {
  height: 50px;
  width: 50px;
}
</style>