import Typesense from 'typesense'
import store from "@/store"

const searchPoint = async (id) => {
    let offline = false
    let assets = {}
    let points = []
    await searchAssets({ search: '*', filter_by: `_points.id:= ${id}`, filterByUnit: false })
        .then(result => {
            if (!result) return offline = true
            // if no results, then asset does not exist
            if (result.hits.length === 0) return false
            result.hits.forEach(h => {
                let confirmed = false
                let aid = h.document.id.split('_')
                if(aid.length > 1) aid = aid[1]
                else aid = aid[0]
                h.document._points.forEach(p => {
                    if(p.id === id) {
                        points.push({ 
                            aid,
                            assetID: h.document.assetID,
                            ...p 
                        })
                        confirmed = true
                    }
                })
                if(confirmed) {
                    assets[aid] = h.document
                }
            })
            return true
        })
        .catch(err => {
            return console.log(`Error checking for point ${id}`, err)
        })
    if(offline) return false
    await searchAssets({ search: '*', filter_by: `assetID:= ${id}`, filterByUnit: false })
        .then(result => {
            if (!result) {
                offline = true
                return true
            }

            // if no results, then asset does not exist
            if (result.hits.length !== 1) return false

            // search returned non-precise match
            result.hits.forEach(h => {
                if (h.document.assetID === id) {
                    let aid = h.document.id.split('_')
                    if(aid.length > 1) aid = aid[1]
                    else aid = aid[0]
                    if(!assets[aid]) {
                        points.push({
                            aid,
                            assetID: h.document.assetID,
                            start: null,
                            id,
                            end: null,
                        })
                        assets[aid] = h.document
                    }
                }
            })
            return true
        }).catch(err => {
            return console.log(`Error checking for asset ${id}`, err)
        })
    return { assets, points }
} 
const searchAssets = async ({ search, query_by, filter_by, filterByUnit }) => {
    if(typeof filterByUnit === 'undefined') filterByUnit = true
    let org = store.state.Db.org
    let unit = store.state.Db.unit
    let nodes = store.state.Db.config?.searchNodes
    let schema = store.state.Db.schema.merge
    // console.log('searchAssets', { search, query_by, filter_by })
    // let nodes = [
    //     {
    //         host: 'search2.trakkassets.com',
    //         port: '443',
    //         protocol: 'https'
    //     }
    // ]
    let tsName = `assets_${org.id}`
    if(!org.searchKey) return 'noSearchKey'   
    let typesense = new Typesense.Client({
        nodes,
        'apiKey': org.searchKey,
        'connectionTimeoutSeconds': 0.5
    })
    let setup = schema?.setup || {}
    const schemaSearch = setup.search || [ setup.amsID || 'amsid', 'type', 'position', 'label']
    let queryBy = 'assetID'
    schemaSearch.forEach(k => {
        queryBy += ','
        queryBy += k
    })
    const searchParameters = {
        q: search, query_by: query_by || queryBy
    }
    if(filterByUnit) searchParameters.filter_by = `_unitID:=[${unit.id}]`
    if(filter_by) {
        if(!searchParameters.filter_by) searchParameters.filter_by = filter_by
        else searchParameters.filter_by += ' && ' + filter_by
    }
    try {
        let result = await typesense.collections(tsName).documents().search(searchParameters)
        // console.log('search result', { searchParameters, result })
        return result
    }
    catch(e) {
        console.log('search error', searchParameters)
        return false
    }
}
const searchH3 = async ({ index, unitID }) => {
    let org = store.state.Db.org
    let nodes = store.state.Db.config?.searchNodes
    let tsName = `assets_${org.id}`
    if(!org.searchKey) return console.log('searchKey not established')
    let typesense = new Typesense.Client({
        nodes,
        'apiKey': org.searchKey,
        'connectionTimeoutSeconds': 2
    })
    const searchParameters = {
        q: '*', filter_by: `_h3ind:= ${index}`, per_page: 100, exclude_fields: '_h3ind'
    }
    if(unitID) {
        searchParameters.filter_by += ` && _unitID:= ${unitID}`
        searchParameters.exclude_fields += `,_unitID`
    }
    let total = 1
    let results = {}
    let page = 1
    const getPage = async () => {
        let params = Object.assign({}, searchParameters, { page })
        let pageResult = await typesense.collections(tsName).documents().search(params).catch(err => {
            console.log('search error')
            // console.log({ nodes, tsName, searchKey: org.searchKey, searchParameters })
            // console.log(err)
            return false
        })
        if(pageResult) {
            total = pageResult.found
            pageResult.hits.forEach(h => {
                results[h.document.id] = h.document
            })
        }
        return pageResult
    }
    const getAllPages = async () => {
        let resultCount = null
        
        while (total > resultCount) {
            await getPage(page)
            resultCount = Object.keys(results).length
            page += 1
            if(page > 100) {
                console.log('reached maximum query count')
                break
            }
        }
        return true
    }
    await getAllPages()
    return results
}

export {
    searchAssets,
    searchH3,
    searchPoint
}
