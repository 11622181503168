import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from '@/store'
import Vuex from "vuex"
import { dbInit } from '@/store/localDb'
import DeviceInfo from '@/mixins/deviceInfo'
import Schema from '@/mixins/schema'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import VueSelect from "vue-select"
// import { VBTooltip } from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './registerServiceWorker'

// for camera UI elements
defineCustomElements(window);

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(Vuex);
Vue.use(DeviceInfo)
Vue.use(Schema)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Vue.use(VueGtag, {
//   config: { id: "G-8PRQWET0ZC" }
// }, router)

Vue.component('v-select', VueSelect)

var cb = function () {
  async function init() {
    new Vue({
      el: '#app',
      router,
      icons,
      store,
      template: '<App/>',
      components: {
        App
      },
    })
  }
  init()
}

window.addEventListener('beforeinstallprompt', (e) => {
  window.pwaPrompt = e
  console.log('beforeinstallprompt')
  store.dispatch('showInstall')
});

dbInit(cb)