
const install = async function (Vue, options) {

  Vue.mixin({
    computed: {
      $device() {
        return this.$store.state.Util.device
      }
    }
  })

  // 4. add an instance method
  // Vue.prototype.$myMethod = function (methodOptions) {
  // }
}
export default { install }
