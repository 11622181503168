import { formatDateString, FirebaseTimestamp } from '../lib/datetime'

export default {
  data() {
    return {
    }
  },
  methods: {
    dateString(value, format) {
      return formatDateString(value, format)
    },
    $dateTime_Timestamp(value, format) {
      const result = FirebaseTimestamp(value, format)
      if (result === null && value !== null) {
        this.logError('date_format', { 
          message: typeof value !== 'string' ? 'Date value not supported' : 'Date format not supported',
          value,
          format
        })
      }
      return result
    }
  }
}
